body {
  background-color: #eee;
  color: #222;
  padding-left: 4em;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5em;
}

#footer {
  font-size: 0.5rem;
  text-align: right;
}
