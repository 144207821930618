body {
  color: #222;
  background-color: #eee;
  padding-left: 4em;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5em;
}

#footer {
  text-align: right;
  font-size: .5rem;
}
/*# sourceMappingURL=index.910e08a4.css.map */
